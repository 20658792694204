/* General */
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
  background-color: #7d7777;
  --table-width: 100%; /* Or any value, this will change dinamically */
}

/*color switch*/
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00a7da;
  background-color: #02b2e9;
}

/*table*/
tbody {
  display: block;
  max-height: 400px;
  overflow-y: auto;
}
thead,
tbody tr {
  display: table;
  width: var(--table-width);
  table-layout: fixed;
}
.table-white {
  color: white;
}

/*Background colors*/
.bg-recintesblue {
  background-color: #02b2e9;
}
.bg-recintesgray {
  background-color: #aaaaaa;
}
.bg-recintesred {
  background-color: #ffe1d9;
}
.bg-recintesred:hover {
  background-color: #e22f03;
}
.bg-recintesgreen {
  background-color: #cfedcf;
}
.bg-recintesgreen:hover {
  background-color: #2d912b;
}
.bg-recinteple {
  background-color: #fffe00;
}
.bg-black {
  background-color: black;
  color: white;
}

/* Colors */
.blue1 {
  color: #02b2e9;
}
.blue2 {
  color: #7da6c3;
}
.light-black {
  color: #333333;
}
.gray {
  color: #7c7c7c;
}
.red {
  color: #e22f03;
}
.red:hover {
  color: #ffe1d9;
}
.green {
  color: #2d912b;
}
.green:hover {
  color: #cfedcf;
}
.black {
  color: #444444;
}

/* Text size */
.counter {
  font-size: 8rem;
  color: #444444;
  font-weight: 800;
}
.big-title {
  font-size: 1.5rem;
}
.medium-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.small-title {
  font-size: 1.05rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}
.normal-text {
  font-size: 0.9rem;
}
.closed-acces-badge {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 700;
}
.section_title {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #444444;
  font-family: "Open Sans", sans-serif;
}

/* Rounded buttons */
.rounded-button {
  border-radius: 50px !important;
}

.btn-outline-light {
  border-color: #6993a9;
  color: #6993a9;
}

.btn-outline-light:hover {
  color: #6993a9;
  background-color: #cee7d0;
}

.btn-outline-white {
  border-color: white;
  color: white;
}

.btn-outline-white:hover {
  color: black;
  background-color: white;
}

/* big buttons + i -*/
.btn-circle.btn-xl {
  width: 100px;
  height: 100px;
  padding: 15px 22px;
  border-radius: 70px;
  font-size: 34px;
  line-height: 1.9;
}

.btn-circle.btn-xxl {
  width: 150px;
  height: 150px;
  padding: 21px 34px;
  border-radius: 105px;
  font-size: 51px;
  line-height: 1.9;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  color: white;
}
.btn-circle:hover {
  color: white;
}
